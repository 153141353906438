import { useEffect } from "react";

function ReferralDogTrainingRedirect() {
    useEffect(() => {
        window.location.href = "https://training.petmojo.care/r-dog-training/";
    }, []);

    return null; // No UI is needed as the user will be redirected immediately
}

export default ReferralDogTrainingRedirect;